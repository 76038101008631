import React from 'react';
import ProjectsUI from '../core-ui/projects/projects-ui';

function Projects() {

  return (
    <ProjectsUI/>
  )
}

export default Projects;
